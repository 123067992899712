import React, { useState } from 'react';
import './Terminal.css';
import metadata from './SatorianCards.json';
import logo from './logo2.png';

function Terminal() {
  const [name, setName] = useState('');
  const [metadataOutput, setMetadataOutput] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleMetadataDisplay = () => {
    const selectedMetadata = metadata.find((item) => item.meta.name.toLowerCase() === name.toLowerCase());

    if (selectedMetadata) {
      let output = '';
      selectedMetadata.meta.attributes.forEach((attribute) => {
        output += `${attribute.trait_type}: ${attribute.value}\n`;
      });
      setMetadataOutput(output);
    } else {
      setMetadataOutput('No metadata found for the given name.');
    }
  };

  return (
    <div>
      <div className="grid"></div>
      <div className="terminal">
        <img src={logo} alt="Logo" className="logo" />
        <h1>ID CARDS</h1>
        <label htmlFor="name">Type .satoria ID:</label>
        <input type="text" id="name" value={name} onChange={handleNameChange} />
        <button onClick={handleMetadataDisplay}>Verify ID</button>
        <pre>{metadataOutput}</pre>
      </div>
      <a href="https://forms.gle/ehqC35FAD131vsz26" target="_blank" rel="noopener noreferrer" className="apply-button">Apply for Citizen</a>
    </div>
  );
}

export default Terminal;
